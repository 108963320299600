import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDpy3cnKdnbAVbo477udhJaTaBiN-DXzLY",
  authDomain: "diamond-king-eb217.firebaseapp.com",
  projectId: "diamond-king-eb217",
  storageBucket: "diamond-king-eb217.appspot.com",
  messagingSenderId: "670817260001",
  appId: "1:670817260001:web:69c22609ea22360fec02a0",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
