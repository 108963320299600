import "./App.css";
import {
  Card,
  Col,
  Container,
  Form,
  Navbar,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import axios from "axios";
import image from "./assets/icons8-diamond-100.png";
import whatsapp from "./assets/icons8-whatsapp-100.png";
const monthsData = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function App() {
  const [data, setData] = useState([]);
  const [month, setMonth] = useState("");
  const [monthName, setMonthName] = useState("");
  const [monthFilter, setMonthFilter] = useState("");
  const [table, setTable] = useState([]);
  const [year, setYear] = useState("");
  const [loadings, setLoadings] = useState(false);

  useEffect(() => {
    setLoadings(true);
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var date = new Date();
    setMonth(months[date.getMonth()]);

    const fetchData = async () => {
      var months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      var date = new Date();

      setYear(date.getFullYear());
      const body = new FormData();
      body.append("api", "sajdh23jd823m023uierur32");
      body.append(
        "emonth",
        monthFilter === "" ? months[date.getMonth()] : monthFilter
      );
      body.append("eyear", date.getFullYear());
      const data = await axios.post(
        "https://dstservices.in/api/game_list.php",
        body
      );
      setTable(data);
    };
    fetchData();
    const unsub = onSnapshot(collection(db, "realTime"), (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setData(list);
      setLoadings(false);
    });

    return () => {
      unsub();
    };
  }, [monthFilter]);
  const convertMonthNameToNum = (num) => {
    const date = new Date();

    date.setMonth(num - 1);

    const name = date.toLocaleString("en-US", { month: "long" });
    setMonthName(name);
  };
  return (
    <>
      <Navbar bg="light">
        <Container className="d-flex justify-content-center">
          <Navbar.Brand
            as="h1"
            className="text-center"
            style={{ fontSize: "35px", fontWeight: "bold" }}>
            <img className="me-2" src={image} height={55} alt="" />
            Diamondking.co.in
          </Navbar.Brand>
        </Container>
      </Navbar>
      <a
        href="whatsapp://send?text=diamondking result - DiamondKing | SilverKing | IronKing | LionKing | online result - https://diamondking.co.in/"
        data-action="share/whatsapp/share">
        <img
          height={50}
          src={whatsapp}
          alt=""
          style={{ position: "fixed", right: "0", top: "40%", zIndex: 100 }}
        />
      </a>

      <Container className="py-5">
        <Card
          className="mb-3 text-center"
          style={{ background: "#FF7043", fontWeight: "bold" }}>
          <Card.Body>
            Result Time : Diamond King (5:30) | Silver King (7:30) | Iron King
            (9:30) | Lion King (11:00)
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="d-flex flex-column ">
            {loadings ? (
              <Spinner className="mx-auto" animation="border" role="status">
                <span className="visually-hidden">Loadings...</span>
              </Spinner>
            ) : (
              <>
                <h1
                  className="text-danger text-center"
                  style={{ textTransform: "capitalize" }}>
                  {data[0]?.game}
                </h1>
                <h3
                  className="text-danger text-center"
                  style={{ textTransform: "capitalize" }}>
                  {data[0]?.number}
                </h3>
              </>
            )}
          </Card.Body>
        </Card>
        <br />
        <Card style={{ borderRadius: 0 }}>
          <Card.Body>
            <Row className="g-3">
              <Col xs={12} sm={12} md={6} lg={4}>
                <Form.Group className=" d-flex align-items-center">
                  <Form.Label as="h5" className="my-auto">
                    Month
                  </Form.Label>
                  <Form.Select
                    className="ms-3"
                    onChange={(e) => {
                      setMonthFilter(e.target.value);
                      convertMonthNameToNum(e.target.value);
                    }}>
                    <option value="">Select Month</option>
                    {monthsData.map((items, index) => {
                      return (
                        <option value={index + 1} key={index}>
                          {items}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card
          style={{
            background: "#FF7043",
            fontWeight: "bold",
            borderRadius: "0px",
          }}>
          <Card.Body>
            <h5 className="text-center">
              Diamond King, Silver King, Iron King and Lion King Result chart{" "}
              {monthFilter === "" ? month : monthName} {year}
            </h5>
          </Card.Body>
        </Card>
        {table?.data?.gamelist === undefined ? (
          <Card>
            <Card.Body>
              <h1 className="text-danger text-center">Not Data Found</h1>
            </Card.Body>
          </Card>
        ) : (
          <Table striped bordered responsive hover>
            <thead>
              <tr>
                <th className="text-center">Date</th>
                <th className="text-center">Diamond King</th>
                <th className="text-center">Silver King</th>
                <th className="text-center">Iron King</th>
                <th className="text-center">Lion King</th>
              </tr>
            </thead>
            <tbody>
              {table?.data?.gamelist?.map((items, index) => {
                return (
                  <tr key={index}>
                    <td className="text-danger text-center fw-bold">
                      {items.day}
                    </td>
                    <td className="text-center fw-bold">{items.diamond}</td>
                    <td className="text-center fw-bold">{items.silver}</td>
                    <td className="text-center fw-bold">{items.iron}</td>
                    <td className="text-center fw-bold">{items.lion}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Container>
    </>
  );
}

export default App;
